/* Animations */
.icon.animation path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    animation: dash linear forwards;
    animation-duration: 3s;
}

@keyframes dash {
    to {
        stroke-dashoffset: 0;
    }
}
